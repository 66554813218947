<template>
  <fw-layout :back-to-enable="false">
    <template #header-nav>
      <div class="flex">
        <HeaderSwitchManage />
      </div>
    </template>
    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-4 mb-10" version="v2"></PanelUCIdHero>

      <fw-panel :title="$t('quickAccess')" class="my-5">
        <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
          <button-quick-access
            v-if="lastCall && lastCall.key && userRoles.can_access_calls"
            :label="lastCall.prefix + lastCall.code"
            :title="$t('openLastCall')"
            icon="cube"
            @clicked="openPage('last', lastCall.key)"
          />
          <button-quick-access
            v-if="userRoles.can_access_calls"
            :label="$t('calls')"
            :title="$t('openCalls')"
            icon="cube"
            @clicked="openPage('all')"
          />
          <button-quick-access
            v-if="userRoles.can_access_appointments"
            :label="$t('mdt')"
            :title="$t('medicalAppointments')"
            icon="calendar"
            @clicked="$router.push('/manage/appointments')"
          />
          <button-quick-access
            v-if="isAdmin"
            :label="$t('audit')"
            :title="$t('activity')"
            icon="activity"
            @clicked="$router.push('/manage/activity')"
          />
          <button-quick-access
            v-if="isAdmin"
            :label="$t('audit')"
            :title="$t('notifications')"
            icon="message-sent"
            @clicked="$router.push('/manage/notifications')"
          />
        </div>
      </fw-panel>

      <WeeklyEventsPanel
        class="mt-5"
        :loading="loadingWeeksPanel"
        :weeks="weeks"
        :show-weekend="true"
        @get-week-events="getUserWeekEvents"
      >
        <template #events="{events}">
          <div v-for="(event, ee) in events" :key="'event_' + ee">
            <div
              v-if="event.type === 'applications' && event.submitted"
              class="eventbox eventbox-transparent cursor-pointer flex justify-between gap-1"
              @click="$router.push(`/manage/call/${weekCalls[event.call].key}/dashboard`)"
            >
              <div class="flex-1">
                <div>
                  <span class="font-medium">{{ weekCalls[event.call].prefix }}</span>
                  <span class="font-bold">{{ weekCalls[event.call].code }}</span>
                </div>
                <div class="text-xs font-medium">
                  <span>Candidaturas submetidas</span>
                </div>
              </div>
              <div class="flex-shrink-0">
                <fw-tag size="xs" type="light-primary">
                  <span>+{{ event.submitted }}</span>
                </fw-tag>
              </div>
            </div>
            <div
              v-else-if="['start', 'end'].includes(event.type)"
              class="eventbox flex gap-2 cursor-pointer"
              :class="{
                'bg-primary': event.type === 'start' && weekCalls[event.call].state === 'published',
                'bg-gray-500': weekCalls[event.call].state !== 'published',
                'bg-red-400': weekCalls[event.call].type === 'end' && weekCalls[event.call].state === 'published',
              }"
              @click="$router.push(`/manage/call/${weekCalls[event.call].key}/dashboard`)"
            >
              <div>
                <div>
                  <span class="font-medium">{{ weekCalls[event.call].prefix }}</span>
                  <span class="font-bold">{{ weekCalls[event.call].code }}</span>
                </div>
                <div class="text-sm font-semibold">Abertura de candidaturas</div>
                <div class="text-xs font-medium truncate">
                  {{ weekCalls[event.call].title[language] || 'Sem título' }}
                </div>
              </div>
            </div>
            <div
              v-else-if="event.type == 'reservations' && event.submitted"
              class="eventbox eventbox-transparent cursor-pointer flex justify-between gap-1"
              @click="$router.push(`/manage/appointment/${event.appointment}/reservations`)"
            >
              <div class="flex-1">
                <div>
                  <span class="font-medium">{{ weekAppointments[event.appointment].prefix }}</span>
                  <span class="font-bold">{{ weekAppointments[event.appointment].code }}</span>
                </div>
                <div class="text-xs font-medium">
                  <span>Agendamentos submetidos</span>
                </div>
              </div>
              <div class="flex-shrink-0">
                <fw-tag size="xs" type="light-primary">
                  <span>+{{ event.submitted }}</span>
                </fw-tag>
              </div>
            </div>
            <div
              v-else-if="event.type == 'appointment-end'"
              class="eventbox flex gap-2 cursor-pointer"
              :class="{
                'bg-red-400': weekAppointments[event.appointment].state === 'published',
                'bg-gray-500': weekAppointments[event.appointment].state !== 'published',
              }"
              @click="$router.push(`/manage/appointment/${event.appointment}`)"
            >
              <div>
                <div>
                  <span class="font-medium">{{ weekAppointments[event.appointment].prefix }}</span>
                  <span class="font-bold">{{ weekAppointments[event.appointment].code }}</span>
                </div>
                <div class="text-sm font-semibold">Encerramento dos agendamentos</div>
              </div>
            </div>
            <div class="event-separator"></div>
          </div>
        </template>
      </WeeklyEventsPanel>

      <fw-panel-info debug label="Data (raw)" class="mt-5">
        <json-viewer :value="{ lastCall, weeks, weekCalls, weekAppointments, userRoles }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import HeaderSwitchManage from '@/components/header/HeaderSwitchManage'
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import WeeklyEventsPanel from '@/fw-modules/fw-core-vue/ui/components/panels/WeeklyEventsPanel.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    WeeklyEventsPanel,
    PanelUCIdHero,
    HeaderSwitchManage,
    ButtonQuickAccess,
  },

  data() {
    return {
      loading: false,
      loadingWeeksPanel: false,
      lastCall: null,
      weeks: {},
      weekCalls: {},
      weekAppointments: {},
    }
  },

  computed: {
    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },

    // Make sure exam is not allowed in small devices
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },

    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    user() {
      return this.$store.getters.getUser
    },

    userRoles() {
      return this.$store.getters.userPermissions
    },

    isAdmin() {
      return this.user.roles && this.user.roles.includes('splus-admin')
    },
  },

  mounted() {
    this.loadLocalLastCall()
  },

  methods: {
    async getUserWeekEvents(weekNumber, year) {
      this.loadingWeeksPanel = true

      try {
        const data = await this.api.getUserWeekEvents(weekNumber, year)
        console.log('getUserWeekEvents', data)
        this.weeks = { ...this.weeks, ...data.weeks }
        this.weekCalls = { ...this.weekCalls, ...data.calls }
        await this.getAppointmentsUserWeekEvents(weekNumber, year)
      } catch (error) {
        console.error(error)
      }

      await utils.sleep(250)
      this.loadingWeeksPanel = false
    },

    async getAppointmentsUserWeekEvents(weekNumber, year) {
      try {
        const data = await this.api.getAppointmentsUserWeekEvents(weekNumber, year)
        console.log('getAppointmentsUserWeekEvents', data)
        this.weeks = { ...this.weeks, ...data.weeks }
        this.weekAppointments = { ...this.weekAppointments, ...data.appointments }
      } catch (error) {
        console.error(error)
      }
    },

    // Utils
    openPage(page, key) {
      if (page === 'all') {
        this.$router.push({ path: '/manage/calls' })
      } else if (page === 'new') {
        this.$router.push({ path: '/manage/calls/new' })
      } else if (page === 'last') {
        this.$router.push({ path: '/manage/call/' + key + '/dashboard' })
      }
    },
    loadLocalLastCall() {
      const lsLast = localStorage.getItem('last-call-open')
      if (lsLast) this.lastCall = JSON.parse(lsLast)
      return false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "call": "Procedimento",
    "quickAccess": "Acesso rápido",
    "openLastCall": "Abrir último procedimento",
    "createCall": "Criar novo procedimento",
    "openCalls": "Ver todos os procedimentos",
    "audit": "Auditoria",
    "calls": "Procedimentos",
    "people": "Pessoas",
    "activity": "Atividade",
    "notifications": "Notificações",
    "medicalAppointments": "Chamadas para consultas",
    "mdt": "Medicina do Trabalho"
  },
  "en": {
    "call": "Procedimento",
    "quickAccess": "Acesso rápido",
    "openLastCall": "Abrir último procedimento",
    "createCall": "Criar novo procedimento",
    "openCalls": "Ver todos os procedimentos",
    "audit": "Audit",
    "calls": "calls",
    "people": "People",
    "activity": "Activity",
    "notifications": "Notifications",
    "medicalAppointments": "Medical Appointments",
    "mdt": "MDT"
  }
}
</i18n>
